<template>
  <v-preloader
      v-if="!rendered"
      static
      clear
  />
  <v-chart
      v-else
      ref="chart"
      v-model="chartData"
      lib="highcharts"
      dataforce
  />
</template>

<script>
import {hmsToSeconds, secondsToHms} from '@/function'

export default {
  name: 'segmentMetricChart',

  props: {
    items: {
      type: Array,
      default: []
    },

    metric: {
      type: Object,
      default: () => ({})
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    dateFiltersFormatted: {
      type: Object,
      default: () => ({})
    },

    controlGroup: {
      type: Object,
      default: undefined
    },

    idKey: {
      type: String,
      default: 'catalog_id'
    }
  },

  data () {
    return {
      rendered: true
    }
  },

  computed: {
    itemsSorted () {
      return this.items.sort((a, b) => b[`${this.metric.id}${this.dateFilters.comparison ? '_one' : ''}`] - a[`${this.metric.id}${this.dateFilters.comparison ? '_one' : ''}`])
    },

    chartData () {
      const self = this
      let series = []

      if (this.dateFilters.comparison) {
        try {
          this.itemsSorted.forEach(item => {
            let newItem = {
              linkedTo: item[this.idKey],
              name: `${this.dateFiltersFormatted.two.from} - ${this.dateFiltersFormatted.two.to}`,
              color: '#BDBDBD',
              pointPlacement: -0.2,
              data: [{
                name: item.url_title || item.name,
                previous: true,
                y: item[`${this.metric.id}_two`] || 0
              }]
            }

            series.push(newItem)
          })
        } catch (_) {}

        if (this.controlGroup) {
          let controlGroupItem = {
            linkedTo: 0,
            name: `${this.dateFiltersFormatted.two.from} - ${this.dateFiltersFormatted.two.to}`,
            color: '#BDBDBD',
            pointPlacement: -0.2,
            data: [{
              name: 'Контрольная группа',
              previous: true,
              y: this.controlGroup.two || 0
            }]
          }

          series.push(controlGroupItem)
        }
      }

      try {
        this.itemsSorted.forEach(item => {
          let newItem = {
            id: item[this.idKey],
            name: `${this.dateFiltersFormatted.one.from} - ${this.dateFiltersFormatted.one.to}`,
            data: [{
              name: item.url_title || item.name,
              y: item[`${this.metric.id}${this.dateFilters.comparison ? '_one' : ''}`] || 0
            }]
          }

          series.push(newItem)
        })
      } catch (_) {}

      if (this.controlGroup) {
        let controlGroupItem = {
          id: 0,
          name: `${this.dateFiltersFormatted.one.from} - ${this.dateFiltersFormatted.one.to}`,
          data: [{
            name: 'Контрольная группа',
            y: this.controlGroup.one || 0
          }]
        }

        series.push(controlGroupItem)
      }

      let categories = [...this.itemsSorted.map(item => item.url_title || item.name)]

      if (this.controlGroup) {
        categories = [...categories, 'Контрольная группа']
      }

      return {
        chart: {
          type: 'column'
        },

        legend: {
          enabled: false
        },

        xAxis: {
          type: 'category',
          categories,
          labels: {
            autoRotation: undefined,
            style: {
              color: '#2D3646'
            }
          }
        },

        yAxis: {
          gridLineDashStyle: 'Dash',
          labels: {
            formatter: function () {
              return self.metric.additional
                  ? this.value.toFixed(2).toLocaleString() + self.metric.additional
                  : this.value.toLocaleString()
            }
          }
        },

        plotOptions: {
          column: {
            borderWidth: 0,
            borderRadius: 3,
            grouping: false
          }
        },

        tooltip: {
          headerFormat: '',
          pointFormatter: function () {
            const value = self.metric.additional
                  ? this.y.toFixed(2).toLocaleString() + self.metric.additional
                  : this.y.toLocaleString()

            return `
            <div style="line-height: 18px;">
                <span style="font-size: 16px; color:${this.color};">\u25CF</span> ${self.dateFiltersFormatted[this.options.previous ? 'two' : 'one'].from} - ${self.dateFiltersFormatted[this.options.previous ? 'two' : 'one'].to}: <b>${value}</b>
            </div>
          `
          }
        },

        series,

        exporting: {
          enabled: false
        }
      }
    }
  },

  watch: {
    controlGroup () {
      this.rerenderChart()
    }
  },

  mounted () {
    if (this.$refs.chart) {
      this.$refs.chart.$refs.highcharts.chart.reflow()
    }
  },

  methods: {
    rerenderChart () {
      this.rendered = false

      setTimeout(() => {
        this.rendered = true
      }, 1000)
    },
  }
}
</script>

<style scoped lang="scss">
</style>

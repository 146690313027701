import {transformationInTreeV2} from '@/function'

import treeNode from './treeNode'

export default {
  components: {
    'v-tree-node': treeNode
  },

  props: {
    metrics: {
      type: Array,
      default: () => ([])
    },

    metricActive: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      guiValue: undefined,

      search: ''
    }
  },

  computed: {
    metricsTree () {
      let tree = []

      let list = JSON.parse(JSON.stringify(this.metrics))

      list = list.filter(metric => String(metric.name).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)

      tree = {items: transformationInTreeV2(list)}

      if (this.search) {
        return tree.items
      } else if (tree.items && tree.items.length === 1) {
        return tree.items[0].items
      }

      return tree.items
    },

    metricActiveName() {
      const metric = this.metrics.find(item => item.id === this.metricActive)

      return (metric && metric.name) || ''
    }
  },

  watch: {
    metricActive (val) {
      this.setValue(val)
    }
  },

  created () {
    this.setValue(this.metricActive)
  },

  methods: {
    setValue (value) {
      this.$set(this, 'guiValue', value)
    },

    updateFilters (id) {
      this.$emit('update-url', {metric: id})
    }
  }
}

<template>
  <li class="tree-node">
    <div :class="['tree-node__title', {_selectable: !data.items, _active: active}]">
      <div
          class="tree-node__title-wrapper"
          :style="`padding-left: ${level * 16}px`"
          @click="clickNode"
      >
        <svg
            v-if="data.items"
            :class="['tree-node__arrow', { '_open': activeList } ]"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z" />
        </svg>

        <span :class="['tree-node__title-text', {'_pl-32': !data.items}]">{{ data.name }}</span>
      </div>
    </div>

    <ul
        v-if="showList"
        v-show="activeList"
    >
      <template v-if="data.items">
        <v-tree-node
          v-for="(item, i) in data.items"
          :key="i"
          v-bind="{
            level: level + 1,
            data: item,
            active: metricActive === item.id,
            metricActive
          }"
          @open="open"
          @toggle-checkbox="$emit('toggle-checkbox', item.id)"
        />
      </template>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'v-tree-node',

  props: {
    data: {
      type: Object,
      default: null
    },

    level: {
      type: Number,
      default: 1
    },

    active: {
      type: Boolean,
      default: false
    },

    metricActive: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      activeList: false
    }
  },

  computed: {
    showList () {
      return this.data.items && this.data.items.length
    }
  },

  created () {
    this.activeList = this.data.items && Array.isArray(this.data.items) && this.data.items.some(item => item.id === this.metricActive)
  },

  methods: {
    toggleList () {
      this.activeList ^= true
    },

    open () {
      if (!this.activeList) {
        this.activeList = true
        this.$emit('open')
      }
    },

    clickNode () {
      if (!this.data.items) {
        this.$emit('toggle-checkbox', this.data.id)
      } else {
        this.toggleList()
      }
    }
  }
}
</script>
